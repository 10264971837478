import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

class Footer extends React.Component {
  static defaultProps = {
    showCopyright: true,
  }

  render() {
    return (
      <footer className="footer">
        <div className="inner">
          <ul className="icons">
            {/*
              <li>
              <a href="#" className="icon fa-twitter">
                <span className="label">Twitter</span>
              </a>
              </li>
              <li>
              <a href="#" className="icon fa-github">
                <span className="label">Github</span>
              </a>
              </li>
              <li>
              <a href="#" className="icon fa-dribbble">
                <span className="label">Dribbble</span>
              </a>
              </li>
            */}
            <li>
              <a href="mailto:ivana.jelinkova@ivanaart.cz" className="icon" aria-label="E-mail">
                <FA icon={faEnvelope} />
                <span className="label">E-mail</span>
              </a>
            </li>
          </ul>
          {this.props.showCopyright && (
            <ul className="copyright">
              <li>&copy; Ivana Jelínková</li>
              <li>
                Original theme design: <a href="http://html5up.net">HTML5 UP</a>
              </li>
            </ul>
          )}
        </div>
      </footer>
    )
  }
}

export default Footer
