import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Footer from './Footer'

export default function Header(props) {
  const avatar = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "avatar.jpg" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [50, 80, 100, 140, 170, 200, 300, 400]
            traceSVG: { blackOnWhite: false }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <header id="header">
      <div className="inner">
        <Link className="image avatar" to="/">
          <Img
            fluid={avatar.file.childImageSharp.fluid}
            alt={props.author}
            title={props.author}
          />
        </Link>
        <Link to="/">
          <h1>
            <strong>
              {props.author} / {props.title}
            </strong>
          </h1>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: props.body }} />
      </div>
      <Footer showCopyright={false} />
    </header>
  )
}
