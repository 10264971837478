import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import '../assets/scss/main.scss'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import Header from '../components/Header'
import Footer from '../components/Footer'

export default ({ children, location, title, description, image }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        block: markdownRemark(
          fileAbsolutePath: { regex: "//blocks/header\\\\./" }
        ) {
          html
        }
      }
    `}
    render={(data) => {
      const {
        title: siteTitle,
        author: siteAuthor,
        description: siteDescription,
        siteUrl,
      } = data.site.siteMetadata
      const headerBody = data.block.html
      const isHome = location.pathname === '/'
      let titleTemplate = `${siteAuthor} / ${siteTitle}`
      if (!isHome && title) titleTemplate = '%s / ' + titleTemplate

      const metaTitle = titleTemplate.replace(/%s/g, title)
      const metaDescription = description || siteDescription
      const twitterCard = image ? 'summary_large_image' : 'summary'
      const baseUrl = (location.origin ? location.origin : siteUrl).replace(
        /\/$/,
        ''
      )
      const url = baseUrl + location.pathname

      return (
        <div>
          <Helmet
            titleTemplate={titleTemplate}
            htmlAttributes={{
              lang: 'cs',
            }}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                name: `twitter:card`,
                content: twitterCard,
              },
              {
                name: `twitter:creator`,
                content: siteAuthor,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat(
              image
                ? [
                    {
                      name: `twitter:image`,
                      content: baseUrl + image,
                    },
                    {
                      name: `og:image`,
                      content: baseUrl + image,
                    },
                  ]
                : []
            )}
          >
            <link rel="preconnect" href="https://www.google-analytics.com" />
            <title>{title || ' '}</title>
          </Helmet>
          <Header author={siteAuthor} title={siteTitle} body={headerBody} />
          <div id="main">
            {!isHome && (
              <Link to="/">
                <FA icon={faHome} /> {siteTitle}
              </Link>
            )}
            {children}
            <hr />
            <Footer />
          </div>
        </div>
      )
    }}
  />
)
